import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";

export default function Navigation() {
  return (
    <Navbar bg="dark" data-bs-theme="dark">
      <Container>
        <Navbar.Brand href="/">
          <img
            alt=""
            src="./fav.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{" "}
          Catapult RoyaltyMailer
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Wizard</Nav.Link>
            <Nav.Link href="/payees">Payees</Nav.Link>
            <Nav.Link href="https://dashboard.stripe.com/connect/accounts/overview">
              Payouts
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Button href="https://infinitecatalog.com" variant="primary">
          Open Infinite Catalog
        </Button>
      </Container>
    </Navbar>
  );
}
