import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import {
  Badge,
  Modal,
  Button,
  ListGroup,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";

function PayeesList() {
  const [payees, setPayees] = useState([]);
  const [selectedPayee, setSelectedPayee] = useState(null);
  const [paypalDetail, setPaypalDetail] = useState(null);
  const [venmoDetail, setVenmoDetail] = useState(null);
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    const fetchPayees = async () => {
      const { data, error } = await supabase.from("payees").select("*");
      if (error) {
        console.error("Error fetching payees:", error);
      } else {
        setPayees(data);
      }
    };

    fetchPayees();
  }, []);

  const fetchPaymentDetails = async (payee) => {
    // ... [rest of the function]
  };

  const handlePayeeSelect = (payee) => {
    setSelectedPayee(payee);
    fetchPaymentDetails(payee);
  };

  const handleModalClose = () => {
    setSelectedPayee(null);
    setPaypalDetail(null);
    setVenmoDetail(null);
  };

  const filteredPayees = payees.filter((payee) => {
    if (filter === "all") return true;
    return payee.payment_preference === filter;
  });

  const sortedPayees = [...filteredPayees].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const radios = [
    { name: "All", value: "all" },
    { name: "Stripe", value: "Stripe" },
    { name: "PayPal", value: "paypal" },
    { name: "Venmo", value: "venmo" },
  ];

  return (
    <div>
      <br />
      <ButtonGroup toggle>
        {radios.map((radio, idx) => (
          <ToggleButton
            key={idx}
            type="radio"
            variant="secondary"
            name="radio"
            value={radio.value}
            checked={filter === radio.value}
            onClick={() => setFilter(radio.value)}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
      <br />
      <br />
      <ListGroup>
        {sortedPayees.map((payee) => (
          <ListGroup.Item
            key={payee.id}
            action
            onClick={() => handlePayeeSelect(payee)}
          >
            <b>{payee.name} </b>
            {payee.payment_preference === "Stripe" && (
              <Badge bg="dark">Stripe</Badge>
            )}
            {payee.payment_preference === "paypal" && (
              <Badge bg="primary">PayPal</Badge>
            )}
            {payee.payment_preference === "venmo" && (
              <Badge bg="info">Venmo</Badge>
            )}
            <br />
            {payee.email}
          </ListGroup.Item>
        ))}
      </ListGroup>

      {selectedPayee && (
        <Modal show onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedPayee.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <b>Email:</b> {selectedPayee.email}
            </p>
            <p>
              <b>Payment Preference:</b> {selectedPayee.payment_preference}
            </p>
            {selectedPayee.payment_preference === "paypal" && paypalDetail && (
              <p>
                <b>PayPal Address:</b> {paypalDetail.paypal_address}
              </p>
            )}
            {selectedPayee.payment_preference === "venmo" && venmoDetail && (
              <>
                <p>
                  <b>Venmo Handle:</b> {venmoDetail.venmo_handle}
                </p>
                <p>
                  <b>Venmo Phone:</b> {venmoDetail.venmo_phone_number}
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default PayeesList;
