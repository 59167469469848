import React, { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import Swal from "sweetalert2";

function SendEmail() {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [payees, setPayees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPayees = async () => {
      try {
        const { data, error } = await supabase.from("payees").select("*");
        if (error) {
          console.error("Error fetching payees:", error);
        } else {
          setPayees(data || []);
        }
      } catch (error) {
        console.error("Error fetching payees:", error);
      }
    };

    fetchPayees();
  }, []);

  const handleSend = async () => {
    setIsLoading(true);
    console.log("handleSend triggered");
    for (let payee of payees) {
      const expectedFileName = `${payee.name} - ${subject}.xlsx`;

      const file = await fetchFromAWS(expectedFileName);

      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const base64Content = reader.result.split(",")[1];

          const emailData = {
            toEmail: payee.email,
            subject: subject,
            message: message,
            attachment: base64Content,
            filename: expectedFileName,
          };

          try {
            const response = await fetch("/api/send-email", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(emailData),
            });

            if (!response.ok) {
              const errorData = await response.json();
              console.error("Error sending email:", errorData);
            }
          } catch (error) {
            console.error("Error sending email:", error);
          }
        };
        reader.onerror = (error) => {
          console.error("Error reading file:", error);
        };
      }
      setIsLoading(false);
    }

    Swal.fire(
      "Emails Sent!",
      "Emails were successfully sent to all payees.",
      "success"
    );
  };

  const getTemplateDates = () => {
    const currentDate = new Date();
    const lastMonthDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    const twoMonthsAgoDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 2,
      1
    );

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return {
      lastMonth: monthNames[lastMonthDate.getMonth()],
      twoMonthsAgo: monthNames[twoMonthsAgoDate.getMonth()],
    };
  };

  const TEMPLATE = `Hello,

Your [Payout Period] earnings were reported and paid to you today. This is a receipt/notice of payment.

This payout period includes the following:
• DSP earnings from [TWO MONTHS AGO] 2023
• Bandcamp earnings from [LAST MONTH] 2023
• Physical earnings from [LAST MONTH] 2023
• Additional income & expenses incurred during [LAST MONTH] 2023

Your statement is attached.

Login to your Catapult Connect account for payout details and your Infinite Catalog interactive statement.

Thank you!
Catapult Recordings`;

  const insertTemplate = () => {
    const dates = getTemplateDates();

    const filledTemplate = TEMPLATE.replace("[Payout Period]", subject) // This will replace [Payout Period] with the value in the 'subject' state.
      .replace("[TWO MONTHS AGO]", dates.twoMonthsAgo)
      .replace(/\[LAST MONTH\]/g, dates.lastMonth); // Using a regex to replace all occurrences

    setMessage(filledTemplate);
  };

  return (
    <div>
      <div className="mb-3">
        <label className="form-label">Payout Period</label>
        <input
          type="text"
          className="form-control"
          placeholder="Must match IC payout period"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label className="form-label">Message</label>
        <br />
        <button className="btn btn-secondary mb-3" onClick={insertTemplate}>
          Insert Template
        </button>
        <textarea
          className="form-control"
          placeholder="Consider using the template?"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows="17"
        ></textarea>
      </div>
      {isLoading && (
        <div className="mt-3">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Sending...</span>
          </div>
          <span className="ms-2">Sending emails...</span>
        </div>
      )}
      <button
        className="btn btn-primary"
        onClick={handleSend}
        disabled={isLoading}
      >
        Send Emails
      </button>
    </div>
  );
}

async function fetchFromAWS(filename) {
  try {
    const response = await fetch(`/api/fetch-file?filename=${filename}`);
    if (response.ok) {
      const blob = await response.blob();
      return new File([blob], filename, {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
    } else {
      console.error("Error fetching file from AWS:", await response.text());
      return null;
    }
  } catch (error) {
    console.error("Error fetching file from AWS:", error);
    return null;
  }
}

export default SendEmail;
