// App.js

import React, { useState, useEffect } from "react";
import Navigation from "./components/Navigation";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Wizard from "./components/Wizard";
import Payees from "./components/Payees";
import Help from "./components/Help";
import { supabase } from "./supabaseClient";
import Footer from "./components/Footer";
import { ClerkProvider, useClerk, RedirectToSignIn } from "@clerk/clerk-react";

if (!"pk_test_cHJlc2VudC1nb2F0LTk2LmNsZXJrLmFjY291bnRzLmRldiQ") {
  throw new Error("Missing Publishable Key");
}
const clerkPubKey = "pk_live_Y2xlcmsuY2F0YXB1bHRyZWNvcmRpbmdzZ3JvdXAuY29tJA";

function RoutesBasedOnAuth() {
  const clerk = useClerk();

  if (!clerk.session) {
    return <RedirectToSignIn />;
  }

  return (
    <>
      <Navigation />
      <Routes>
        <Route path="/" element={<Wizard />} />
        <Route path="/payees" element={<Payees />} />
        <Route path="/help" element={<Help />} />
      </Routes>
      <Footer />
    </>
  );
}

function ClerkProviderWithRoutes() {
  const navigate = useNavigate();

  return (
    <ClerkProvider publishableKey={clerkPubKey} navigate={(to) => navigate(to)}>
      <RoutesBasedOnAuth />
    </ClerkProvider>
  );
}

export default function App() {
  // eslint-disable-next-line
  const [payees, setPayees] = useState([]);

  const fetchPayees = async () => {
    try {
      const { data, error } = await supabase.from("payees").select("*");

      if (error) {
        console.error("Error fetching payees:", error);
        return;
      }

      setPayees(data);
    } catch (err) {
      // Catch any other unexpected errors
      console.error("Unexpected error fetching payees:", err);
    }
  };

  useEffect(() => {
    fetchPayees();
  }, []);

  return (
    <BrowserRouter>
      <ClerkProviderWithRoutes />
    </BrowserRouter>
  );
}
