import React, { useState, useEffect } from "react";
import FileUpload from "./FileUpload";
import { Container } from "react-bootstrap";
import SendEmail from "./SendEmail";
import { supabase } from "../supabaseClient"; // Assuming you have this setup for Supabase client

export default function Wizard() {
  const [payees, setPayees] = useState([]);

  useEffect(() => {
    const fetchPayees = async () => {
      try {
        const { data, error } = await supabase.from("payees").select("*");
        if (error) {
          console.error("Error fetching payees:", error);
        } else {
          setPayees(data || []);
        }
      } catch (error) {
        console.error("Error fetching payees:", error);
      }
    };

    fetchPayees();
  }, []);

  console.log("wizard ready to go!");

  return (
    <Container>
      <br />
      <h1>Mailing Wizard</h1>
      <br />
      <h3>Step One: Upload Statements from IC</h3>
      <FileUpload payees={payees} />
      <br />
      <h3>Step Two: Send Statements to Payees</h3>
      <SendEmail payees={payees} />
    </Container>
  );
}
