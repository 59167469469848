import { useState } from "react";
import { supabase } from "../supabaseClient";
import { Form, Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";

function PayeeForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [paymentPreference, setPaymentPreference] = useState("Stripe");
  const [paypalAddress, setPaypalAddress] = useState("");
  const [venmoHandle, setVenmoHandle] = useState("");
  const [venmoPhone, setVenmoPhone] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    // eslint-disable-next-line no-unused-vars
    const { data, error } = await supabase.from("payees").insert([
      {
        name,
        email,
        payment_preference: paymentPreference,
      },
    ]);

    if (error) {
      Swal.fire("Error saving payee", error.message, "error");
      return;
    }

    const { data: fetchedData, error: fetchError } = await supabase
      .from("payees")
      .select("id")
      .eq("email", email)
      .order("created_at", { ascending: false })
      .limit(1);

    if (fetchError) {
      Swal.fire("Error fetching payee ID", fetchError.message, "error");
      return;
    }

    if (!fetchedData || !fetchedData.length) {
      Swal.fire("Error fetching payee ID", "No payee ID found", "error");
      return;
    }

    const payeeId = fetchedData[0].id;

    if (paymentPreference === "paypal") {
      const { error: paypalError } = await supabase
        .from("paypal_details")
        .insert([
          {
            payee_id: payeeId,
            paypal_address: paypalAddress,
          },
        ]);

      if (paypalError) {
        Swal.fire("Error saving PayPal details", paypalError.message, "error");
        return;
      }
    }

    if (paymentPreference === "venmo") {
      const { error: venmoError } = await supabase
        .from("venmo_details")
        .insert([
          {
            payee_id: payeeId,
            venmo_handle: venmoHandle,
            venmo_phone_number: venmoPhone,
          },
        ]);

      if (venmoError) {
        Swal.fire("Error saving Venmo details", venmoError.message, "error");
        return;
      }
    }
    Swal.fire(
      "Payee saved successfully!",
      "Everything went according to plan!",
      "success"
    );
  };

  const validateForm = () => {
    if (!name.trim()) {
      Swal.fire("Name is required.", "Please fix your entry", "warning");
      return false;
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) {
      Swal.fire("Email is required.", "Please fix your entry", "warning");
      return false;
    }

    if (paymentPreference === "paypal" && !paypalAddress.trim()) {
      Swal.fire(
        "PayPal address is required.",
        "Please fix your entry",
        "warning"
      );
      return false;
    }

    if (
      paymentPreference === "venmo" &&
      (!venmoHandle.trim() || !venmoPhone.trim())
    ) {
      Swal.fire(
        "Venmo handle and phone number are required.",
        "Please fix your entry",
        "warning"
      );
      return false;
    }
    console.log({
      name,
      email,
      payment_preference: paymentPreference,
    });

    return true;
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button onClick={handleShow}>Create New Payee</Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Payee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Name:</Form.Label>
              <Form.Control
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email:</Form.Label>
              <Form.Control
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicSelect">
              <Form.Label>Payment Preference:</Form.Label>
              <Form.Select
                value={paymentPreference}
                onChange={(e) => setPaymentPreference(e.target.value)}
              >
                <option value="stripe">Stripe</option>
                <option value="paypal">PayPal</option>
                <option value="venmo">Venmo</option>
              </Form.Select>
            </Form.Group>
            {paymentPreference === "paypal" && (
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>PayPal Address:</Form.Label>
                <Form.Control
                  value={paypalAddress}
                  onChange={(e) => setPaypalAddress(e.target.value)}
                />
              </Form.Group>
            )}
            {paymentPreference === "venmo" && (
              <>
                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Venmo Handle:</Form.Label>
                  <Form.Control
                    value={venmoHandle}
                    onChange={(e) => setVenmoHandle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Venmo Phone:</Form.Label>
                  <Form.Control
                    value={venmoPhone}
                    onChange={(e) => setVenmoPhone(e.target.value)}
                  />
                </Form.Group>
              </>
            )}
            <br />
            <Button type="submit">Save New Payee</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PayeeForm;
