import React, { useState } from "react";
import Swal from "sweetalert2";
import { ProgressBar, Accordion } from "react-bootstrap";

function FileUpload(props) {
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileStatuses, setFileStatuses] = useState({});

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };
  const updateFileStatus = (fileName, status) => {
    setFileStatuses((prevStatuses) => ({
      ...prevStatuses,
      [fileName]: status,
    }));
  };

  const handleUpload = async () => {
    console.log(props.payees);
    let unmatchedFiles = [];
    let successfulUploads = [];
    let failedUploads = [];

    for (let file of files) {
      const formData = new FormData();
      updateFileStatus(file.name, "Uploading");
      let totalFiles = files.length;
      let currentFileIndex = files.indexOf(file);

      formData.append("file", file);

      const fileNameWithoutExtension = file.name.replace(".xlsx", "");
      const payeeName = fileNameWithoutExtension.split(" - ")[0];

      if (!props.payees || props.payees.length === 0) {
        Swal.fire(
          "No payees available.",
          "Please ensure payees are loaded and available.",
          "error"
        );
        return console.log("Loading payees");
      }

      const matchingPayee = props.payees.find(
        (payee) => payee.name === payeeName
      );

      if (!matchingPayee) {
        unmatchedFiles.push(file.name);
        updateFileStatus(file.name, "Mismatch");

        continue;
      }

      try {
        const response = await fetch("/api/upload", {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          successfulUploads.push(file.name);
          updateFileStatus(file.name, "Uploaded");
          setUploadProgress(((currentFileIndex + 1) / totalFiles) * 100);
        } else {
          const errorData = await response.json();
          updateFileStatus(file.name, "Failed");
          setUploadProgress(((currentFileIndex + 1) / totalFiles) * 100);

          failedUploads.push({ name: file.name, reason: errorData.message });
        }
      } catch (error) {
        failedUploads.push({
          name: file.name,
          reason: "Failed to upload the file. Please try again.",
        });
      }
    }

    if (successfulUploads.length > 0) {
      Swal.fire(
        "Upload Successful",
        `Successfully uploaded: ${successfulUploads.join(", ")}`,
        "success"
      );
    }

    if (failedUploads.length > 0) {
      const failedMessages = failedUploads
        .map((f) => `${f.name} - ${f.reason}`)
        .join("\n");
      Swal.fire("Upload Error", failedMessages, "error");
    }

    if (unmatchedFiles.length > 0) {
      Swal.fire(
        "No match available.",
        `Matching payees weren't found for the following files: ${unmatchedFiles.join(
          ", "
        )}`,
        "error"
      );
    }

    setUploadProgress(0);
  };

  const handleClearAllFiles = async () => {
    try {
      const response = await fetch("/api/clear-all-files.js", {
        method: "DELETE",
      });

      if (response.ok) {
        const data = await response.json();
        Swal.fire("Deletion Successful", data.message, "success");
      } else {
        const errorData = await response.json();
        Swal.fire("Deletion Failed", errorData.message, "error");
      }
    } catch (error) {
      Swal.fire(
        "Deletion Failed",
        "Something went wrong. Please try again.",
        "error"
      );
    }
  };

  return (
    <div>
      <div className="mb-3">
        <label className="form-label">Select Files</label>
        <input
          type="file"
          multiple
          className="form-control"
          onChange={handleFileChange}
        />
      </div>
      <button className="btn btn-primary me-2" onClick={handleUpload}>
        Upload
      </button>
      <button className="btn btn-danger me-2" onClick={handleClearAllFiles}>
        Clear All Past Files
      </button>
      <div className="mt-3">
        {uploadProgress > 0 && (
          <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
        )}
      </div>
      <div className="mt-3">
        <Accordion>
          <Accordion.Item>
            <Accordion.Header>Detailed Upload Status</Accordion.Header>
            <Accordion.Body>
              <ul className="list-group">
                {Object.entries(fileStatuses).map(([fileName, status]) => (
                  <li
                    key={fileName}
                    className={`list-group-item list-group-item-${
                      status === "Uploaded"
                        ? "success"
                        : status === "Failed" || status === "Mismatch"
                        ? "danger"
                        : "warning"
                    }`}
                  >
                    {fileName} - {status}
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default FileUpload;
