import React from "react";
import PayeeForm from "./PayeeForm";
import { Container } from "react-bootstrap";
import PayeesTable from "./PayeeTable";

export default function payees() {
  return (
    <Container>
      <br />
      <h1>Payees</h1>
      <br />
      <PayeeForm />
      <br />
      <PayeesTable />
    </Container>
  );
}
